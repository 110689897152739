import Logger from '../services/logger';

// Utility Functions
export const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ||
           (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
};

export const formatTime = (timestamp) => {
    if (!timestamp) return '';
    try {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    } catch (error) {
        Logger.error('Error formatting time:', error);
        return '';
    }
};

export const formatLastSeen = (timestamp) => {
    if (!timestamp) return '';

    try {
        const date = new Date(timestamp);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
            return `at ${date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })}`;
        } else if (diffDays === 1) {
            return `yesterday at ${date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })}`;
        } else if (diffDays < 7) {
            return `${date.toLocaleDateString([], { weekday: 'long' })} at ${date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })}`;
        } else {
            return date.toLocaleDateString([], {
                month: 'short',
                day: 'numeric',
                year: date.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
            });
        }
    } catch (error) {
        Logger.error('Error formatting last seen:', error);
        return '';
    }
};

export const groupMessagesByDate = (messages) => {
    if (!messages) return {};

    const groups = {};
    messages.forEach(message => {
        const date = new Date(message.timestamp);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        let dateStr;
        if (date.toDateString() === today.toDateString()) {
            dateStr = 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            dateStr = 'Yesterday';
        } else {
            dateStr = date.toLocaleDateString(undefined, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        }

        if (!groups[dateStr]) {
            groups[dateStr] = [];
        }
        groups[dateStr].push(message);
    });
    return groups;
};

export const sortMessages = (messages) => {
    if (!messages || !Array.isArray(messages)) return [];
    
    // Remove duplicates based on message ID
    const uniqueMessages = Array.from(
        new Map(messages.map(msg => [msg.id, msg])).values()
    );

    // Sort by sequence and timestamp
    return uniqueMessages.sort((a, b) => {
        if (a.sequence !== b.sequence) {
            return a.sequence - b.sequence;
        }
        // return new Date(a.timestamp) - new Date(b.timestamp);
    });
};

export const getUnreadCount = (messages, userId) => {
    if (!messages || !Array.isArray(messages)) return 0;
    return messages.filter(msg =>
        msg.sender_id !== userId && !msg.read
    ).length;
};
