// src/services/logger.js

class Logger {
    static levels = {
        ERROR: 'ERROR',
        WARN: 'WARN',
        INFO: 'INFO',
        DEBUG: 'DEBUG'
    };

    static log(level, message, data = null) {
        const timestamp = new Date().toISOString();
        
        // Console logging (kept the same as before)
        switch (level) {
            case this.levels.ERROR:
                console.error(timestamp, message, data);
                break;
            case this.levels.WARN:
                console.warn(timestamp, message, data);
                break;
            case this.levels.INFO:
                console.info(timestamp, message, data);
                break;
            case this.levels.DEBUG:
                console.debug(timestamp, message, data);
                break;
            default:
                console.log(timestamp, message, data);
        }

        // Send to server if configured
        if (process.env.REACT_APP_LOG_TO_SERVER === 'true') {
            this.sendToServer({
                timestamp,
                level,
                message,
                data: data ? this.sanitizeData(data) : null
            });
        }
    }

    static sanitizeData(data) {
        // Only include essential data, remove sensitive or unnecessary info
        if (data instanceof Error) {
            return {
                error: data.message,
                stack: data.stack
            };
        }
        return data;
    }

    static error(message, data = null) {
        this.log(this.levels.ERROR, message, data);
    }

    static warn(message, data = null) {
        this.log(this.levels.WARN, message, data);
    }

    static info(message, data = null) {
        this.log(this.levels.INFO, message, data);
    }

    static debug(message, data = null) {
        this.log(this.levels.DEBUG, message, data);
    }

    static async sendToServer(logEntry) {
        if (!process.env.REACT_APP_LOG_ENDPOINT) return;

        const token = localStorage.getItem('token');
        if (!token) return;

        try {
            await fetch(process.env.REACT_APP_LOG_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(logEntry)
            });
        } catch (error) {
            console.error('Failed to send log to server:', error);
        }
    }
}

export default Logger;