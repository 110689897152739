import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import { debounce, set } from 'lodash';
import Logger from './services/logger';
import './App.css';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import { getInitialTheme, formatTime, formatLastSeen, groupMessagesByDate, sortMessages } from './components/UtilityFunc';
const API_URL = '/api';

function App() {
    // States
    const [page, setPage] = useState('login');
    const [user, setUser] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState(new Set());
    const [allMessages, setAllMessages] = useState({});
    const [currentMessages, setCurrentMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [error, setError] = useState('');
    const [isDarkMode, setIsDarkMode] = useState(getInitialTheme());
    const [unreadMessages, setUnreadMessages] = useState({});
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [typingUsers, setTypingUsers] = useState(new Map());
    const [lastMessageTimestamps, setLastMessageTimestamps] = useState({});
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [showChatBox, setShowChatBox] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [editingMessage, setEditingMessage] = useState(null);
    const [messageToDelete, setMessageToDelete] = useState(null);
    const [showReactionPicker, setShowReactionPicker] = useState(null);
    const [messageReactions, setMessageReactions] = useState({});
    const [messageSearchQuery, setMessageSearchQuery] = useState(''); // For message search
    const [messageSearchResults, setMessageSearchResults] = useState([]);
    const [showMessageSearch, setShowMessageSearch] = useState(false);
    const [globalSearchQuery, setGlobalSearchQuery] = useState('');
    const [globalSearchResults, setGlobalSearchResults] = useState({ chats: [], messages: [] });
    const [forwardingMessage, setForwardingMessage] = useState(null);
    const [showForwardDialog, setShowForwardDialog] = useState(false);
    const [isTypingMessage, setIsTypingMessage] = useState(false);
    const [isTypingMessageSearchBar, setIsTypingMessageSearchBar] = useState(false);
    const [isTypingGlobalSearchBar, setIsTypingGlobalSearchBar] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [debouncedMessageValue, setDebouncedMessageValue] = useState('');
    const [pendingMessages, setPendingMessages] = useState(new Set());
    const [messageCache, setMessageCache] = useState(new Map());


    // Refs
    const websocket = useRef(null);
    const messagesContainerRef = useRef(null);
    const userScrollTimeoutRef = useRef(null);
    const typingTimeouts = useRef(new Map());
    const sendingRef = useRef(false);
    const verifyTimeoutRef = useRef(null);
    const currentChatIdRef = useRef(null);
    const searchInputRef = useRef(null);
    const globalSearchInputRef = useRef(null);

    const filteredContacts = useMemo(() => {
        if (!globalSearchQuery) return contacts;
        return contacts.filter(contact => 
            contact.username.toLowerCase().includes(globalSearchQuery.toLowerCase())
        );
    }, [contacts, globalSearchQuery]);
    
    const filteredMessages = useMemo(() => {
        if (!messageSearchQuery || !currentMessages) return [];
        return currentMessages.filter(message =>
            message.content.toLowerCase().includes(messageSearchQuery.toLowerCase())
        );
    }, [currentMessages, messageSearchQuery]);

    // Message Status Component
    const MessageStatus = ({ message }) => {
        const SingleTickIcon = () => (
            <svg className="tick-icon" viewBox="0 0 12 12" fill="currentColor">
                <path d="M8.5 2.5l-.35-.3a.3.3 0 0 0-.4.05L4.2 6.7a.25.25 0 0 1-.38.03L2.1 5.1a.3.3 0 0 0-.4 0l-.32.33a.3.3 0 0 0 0 .4l2.5 2.4a.25.25 0 0 0 .38-.02L8.55 2.9a.3.3 0 0 0-.05-.4z"/>
            </svg>
        );

        const DoubleTickIcon = () => (
            <svg className="tick-icon" viewBox="0 0 12 12" fill="currentColor">
                <path d="M8.5 2.5l-.35-.3a.3.3 0 0 0-.4.05L4.2 6.7a.25.25 0 0 1-.38.03L2.1 5.1a.3.3 0 0 0-.4 0l-.32.33a.3.3 0 0 0 0 .4l2.5 2.4a.25.25 0 0 0 .38-.02L8.55 2.9a.3.3 0 0 0-.05-.4z"/>
                <path d="M11.5 2.5l-.35-.3a.3.3 0 0 0-.4.05L7.2 6.7a.25.25 0 0 1-.38.03l-.27-.25a.25.25 0 0 0-.38.02l-.28.37a.25.25 0 0 0 .02.38l1.45 1.15a.25.25 0 0 0 .38-.02l4.3-6a.3.3 0 0 0-.05-.4z"/>
            </svg>
        );

        return (
            <div className={`message-ticks ${message.read ? 'read' : message.delivered ? 'delivered' : 'sent'}`}>
                {message.read || message.delivered ? <DoubleTickIcon /> : <SingleTickIcon />}
            </div>
        );
    };

    const ScrollToBottomButton = ({ onClick, visible }) => {
        const [isVisible, setIsVisible] = useState(visible);
    
        useEffect(() => {
            let timeoutId;
            if (visible) {
                setIsVisible(true);
            } else {
                timeoutId = setTimeout(() => {
                    setIsVisible(false);
                }, 150);
            }
    
            return () => {
                if (timeoutId) clearTimeout(timeoutId);
            };
        }, [visible]);
    
        if (!isVisible) return null;
    
        return (
            <button
                className={`scroll-to-bottom ${visible ? 'visible' : 'fade-out'}`}
                onClick={onClick}
            >
                ↓
            </button>
        );
    };

    const sendWebSocketMessage = (message) => {
        if (websocket.current?.readyState === WebSocket.OPEN) {
            websocket.current.send(JSON.stringify(message));
        }
    };

    // Add this function
    const scrollToBottom = useCallback(() => {
        if (messagesContainerRef.current) {
            const { scrollHeight, clientHeight } = messagesContainerRef.current;
            messagesContainerRef.current.scrollTop = scrollHeight - clientHeight;
        }
    }, []);

    // Scroll Handling
    const handleScroll = useCallback(
        debounce((e) => {
            const { scrollTop, scrollHeight, clientHeight } = e.target;
            const isNearBottom = scrollHeight - scrollTop - clientHeight < 60;
            setIsAtBottom(isNearBottom);
            setShowScrollButton(!isNearBottom);
        }, 100),
        [isAtBottom]
    );
    
    const sendTypingStatus = useCallback((isTyping) => {
        if (currentChat?.id && websocket.current) {
            try {
                sendWebSocketMessage({
                    type: 'typing_status',
                    receiver_id: currentChat.id,
                    isTyping
                });
            } catch (error) {
                Logger.error('Failed to send typing status:', error);
            }
        }
    }, [currentChat]);

    // Create a debounced version for typing
    const debouncedTypingStart = useCallback(
        debounce((callback) => callback(), 100),
        []
    );

    // Create a debounced version for stopping
    const debouncedTypingStop = useCallback(
        debounce((callback) => callback(), 1000),
        []
    );


    const handleLogin = async (username, password) => {
        try {
            const response = await axios.post(`${API_URL}/login`, {
                username,
                password,
            });
            localStorage.setItem('token', response.data.access_token);
            
            // Set user state - the effect will handle WebSocket connection
            setUser({
                id: response.data.user_id,
                username: response.data.username
            });
            
            setError('');
            setPage('chat');
            fetchContacts();
        } catch (error) {
            setError(error.response?.data?.detail || 'Login failed');
        }
    };

    const handleRegister = async (username, email, password) => {
        try {
            await axios.post(`${API_URL}/register`, {
                username,
                email,
                password,
            });
            setError('');
            setPage('login');
        } catch (error) {
            setError(error.response?.data?.detail || 'Registration failed');
        }
    };

    const handleLogout = async () => {
        try {
            Logger.info('Logging out user');
            const token = localStorage.getItem('token');
            if (token) {
                await axios.post(`${API_URL}/logout`, { userId: user.id }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setOnlineUsers(prevOnlineUsers => {
                    const newOnlineUsers = new Set(prevOnlineUsers);
                    newOnlineUsers.delete(user.id);
                    return newOnlineUsers;
                });
            }
        } catch (error) {
            Logger.error('Logout error:', error);
        } finally {
            Logger.info('Logged out, cleaning up state and local storage');
            if (websocket.current) {
                websocket.current.close();
                websocket.current = null;
            }
            setUser(null);
            setContacts([]);
            setAllMessages({});
            setCurrentMessages([]);
            setCurrentChat(null);
            setUnreadMessages({});
            setPage('login');
            setGlobalSearchQuery('');
            setGlobalSearchResults({ chats: [], messages: [] });
            setMessageSearchQuery('');
            setMessageSearchResults([]);
            localStorage.removeItem('token');
        }
    };

    // API Calls
    const fetchMessages = async (otherUserId) => {
      try {
          const token = localStorage.getItem('token');
          const lastTimestamp = lastMessageTimestamps[otherUserId];
          
          let url = `${API_URL}/messages/${otherUserId}`;
          if (lastTimestamp) {
              url += `?after=${lastTimestamp}`;
          }
  
          const response = await axios.get(url, {
              headers: { Authorization: `Bearer ${token}` }
          });
  
          const sortedMessages = sortMessages(response.data);
  
          if (sortedMessages.length > 0) {
              // Update messageReactions state with reactions from fetched messages
              const newReactions = {};
              sortedMessages.forEach(message => {
                  if (message.reactions && Object.keys(message.reactions).length > 0) {
                      newReactions[message.id] = message.reactions;
                  }
              });
              
              setMessageReactions(prev => ({
                  ...prev,
                  ...newReactions
              }));
  
              setAllMessages(prev => ({
                  ...prev,
                  [otherUserId]: sortMessages([...(prev[otherUserId] || []), ...sortedMessages])
              }));
  
              if (currentChat?.id === otherUserId) {
                  setCurrentMessages(prev => sortMessages([...prev, ...sortedMessages]));
              }
  
              // Update last message timestamp
              const latestMessageTime = Math.max(
                  ...sortedMessages.map(m => new Date(m.timestamp).getTime())
              );
              setLastMessageTimestamps(prev => ({
                  ...prev,
                  [otherUserId]: latestMessageTime
              }));
  
              Logger.info('Updated message reactions:', newReactions);
          }
  
          return sortedMessages;
      } catch (error) {
          Logger.error('Failed to fetch messages:', error);
          return [];
      }
    };

    const fetchContacts = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/users`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setContacts(response.data);

            // Fetch messages for all contacts
            response.data.forEach(contact => {
                fetchMessages(contact.id);
            });
        } catch (error) {
            Logger.error('Failed to fetch contacts:', error);
            if (error.response?.status === 401) {
                handleLogout();
            }
        }
    };

    // Handle delivery confirmation and update message status
    const updateMessageStatus = useCallback((messageData) => {
        Logger.info('Received message status update:', messageData.status);
        // Update message status in state
        setAllMessages(prev => {
            const updatedMessages = { ...prev };
            Object.keys(updatedMessages).forEach(chatId => {
                updatedMessages[chatId] = updatedMessages[chatId].map(msg => {
                    if (msg.id === messageData.message_id) {
                        return {
                            ...msg,
                            delivered: messageData.status === "delivered" || messageData.status === "read",
                            read: messageData.status === "read"
                        };
                    }
                    return msg;
                });
            });
            return updatedMessages;
        });
    
        // Update current messages if needed
        setCurrentMessages(prev =>
            prev.map(msg => {
                if (msg.id === messageData.message_id) {
                    return {
                        ...msg,
                        delivered: messageData.status === "delivered" || messageData.status === "read",
                        read: messageData.status === "read"
                    };
                }
                return msg;
            })
        );
    }, [setAllMessages, setCurrentMessages]);

    const searchMessages = async (query) => {
        if (!query.trim() || !currentChat?.id) {
            setMessageSearchResults([]);
            setIsSearching(false);
            return;
        }

        setIsSearching(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${API_URL}/messages/search/${currentChat.id}?query=${encodeURIComponent(query)}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setMessageSearchResults(response.data);
        } catch (error) {
            Logger.error('Failed to search messages:', error);
            setMessageSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };
  
    const COMMON_EMOJIS = ["👍", "❤️", "😂", "😮", "😢", "🙏", "🎉", "💯"];

    const ReactionPicker = ({ onSelect, onClose, messageId }) => {
        return (
            <div className="reaction-picker" onClick={e => e.stopPropagation()}>
                {COMMON_EMOJIS.map(emoji => (
                    <button
                        key={emoji}
                        className="reaction-button"
                        onClick={() => {
                            onSelect(emoji, messageId);
                            onClose();
                        }}
                    >
                        {emoji}
                    </button>
                ))}
            </div>
        );
    };

    const handleReaction = async (emoji, messageId) => {
      try {
          const token = localStorage.getItem('token');
          const response = await axios.post(
              `${API_URL}/messages/${messageId}/reactions`,
              { emoji: emoji },  // Make sure emoji is sent as an object
              {
                  headers: { 
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              }
          );
          
          setMessageReactions(prev => ({
              ...prev,
              [messageId]: response.data.reaction_counts
          }));
          
      } catch (error) {
          Logger.error('Failed to add reaction:', error);
      }
    };

    const handleEditMessage = async (messageId, newContent) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(
                `${API_URL}/messages/${messageId}`,
                { content: newContent }, // Send content in the proper format
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            setEditingMessage(null);
            
            // Update message in state immediately (optimistic update)
            setCurrentMessages(prev => 
                prev.map(msg => 
                    msg.id === messageId 
                        ? { 
                            ...msg, 
                            content: newContent,
                            is_edited: true,
                            edited_at: response.data.edited_at 
                        } 
                        : msg
                )
            );
            
            setAllMessages(prev => {
                const chatId = currentChat.id;
                const messages = prev[chatId] || [];
                return {
                    ...prev,
                    [chatId]: messages.map(msg =>
                        msg.id === messageId
                            ? {
                                ...msg,
                                content: newContent,
                                is_edited: true,
                                edited_at: response.data.edited_at
                            }
                            : msg
                    )
                };
            });
            
        } catch (error) {
            Logger.error('Failed to edit message:', error);
            // Optionally show error to user
            setEditingMessage(null); // Reset editing state on error
        }
    };

    const handleDeleteMessage = async (messageId) => {
      try {
          const token = localStorage.getItem('token');
          await axios.delete(
              `${API_URL}/messages/${messageId}`,
              {
                  headers: { 
                      'Authorization': `Bearer ${token}`,
                  }
              }
          );
          
          setMessageToDelete(null);
          
          // Update states to remove the message
          setCurrentMessages(prev => prev.filter(msg => msg.id !== messageId));
          
          setAllMessages(prev => {
              const chatId = currentChat.id;
              const messages = prev[chatId] || [];
              return {
                  ...prev,
                  [chatId]: messages.filter(msg => msg.id !== messageId)
              };
          });
          
      } catch (error) {
          Logger.error('Failed to delete message:', error);
          setMessageToDelete(null);
      }
    };

    const handleMessageInputChange = useCallback((e) => {
        const value = e.target.value;
        setNewMessage(value);
        setIsTypingMessage(true);
        
        // Debounced typing status updates
        if (value.trim()) {
            debouncedTypingStart(() => sendTypingStatus(true));
            debouncedTypingStop(() => sendTypingStatus(false));
        } else {
            debouncedTypingStart.cancel();
            debouncedTypingStop.cancel();
            sendTypingStatus(false);
        }
    }, [debouncedTypingStart, debouncedTypingStop, sendTypingStatus]);

    // In handleForwardMessage function:
    const handleForwardMessage = async (receiverId) => {
      try {
          const token = localStorage.getItem('token');
          const response = await axios.post(
              `${API_URL}/messages/forward`,
              {
                  message_id: forwardingMessage.id,
                  receiver_id: receiverId
              },
              {
                  headers: { Authorization: `Bearer ${token}` }
              }
          );
  
          // Create new message object and ensure forwarded flag is set
          const newMessage = {
              ...response.data,
              forwarded: true  // Explicitly set forwarded flag
          };
  
          // Update allMessages state
          setAllMessages(prev => ({
              ...prev,
              [receiverId]: [...(prev[receiverId] || []), newMessage]
          }));
  
          // Clear forwarding states
          setShowForwardDialog(false);
          setForwardingMessage(null);
  
      } catch (error) {
          Logger.error('Failed to forward message:', error);
      }
    };

    // Chat Selection
    const handleChatSelect = useCallback(async (contact) => {
      if (contact.id === currentChat?.id && showChatBox) {
          Logger.info('Same chat selected, skipping', { contactId: contact.id });
          return;
      }
      
      Logger.info('Selecting chat:', { 
          contact,
          contactId: contact.id,
          contactUsername: contact.username
      });
      
      const newChat = {
          id: contact.id,
          username: contact.username,
          last_seen: contact.last_seen
      };
      
      setCurrentChat(newChat);
      setShowChatBox(true);
      
      // Clear existing reactions for this chat
      setMessageReactions({});
      
      // Use existing messages first
      const existingMessages = allMessages[contact.id] || [];
      setCurrentMessages(sortMessages(existingMessages));
      
      // Initialize reactions from existing messages
      const existingReactions = {};
      existingMessages.forEach(message => {
          if (message.reactions && Object.keys(message.reactions).length > 0) {
              existingReactions[message.id] = message.reactions;
          }
      });
      setMessageReactions(existingReactions);
      
      Logger.info(`Selected chat with ${contact.id}. Loading existing messages:`, {
          messageCount: existingMessages.length,
          existingReactions: existingReactions
      });
  
      try {
          await fetchMessages(contact.id);
          
          const hasUnreadMessages = existingMessages.some(msg => 
              msg.receiver_id === user?.id && !msg.read
          );
          if (hasUnreadMessages && websocket.current) {
              Logger.info('Marking unread messages as read');
              existingMessages
                  .filter(msg => msg.receiver_id === user?.id && !msg.read)
                  .forEach(msg => {
                      sendWebSocketMessage({
                          type: 'message_status',
                          message_id: msg.id,
                          status: 'read',
                          content: msg.content
                      });
                  });
          }
  
          setUnreadMessages(prev => ({
              ...prev,
              [contact.id]: 0
          }));
  
          scrollToBottom();
          
      } catch (error) {
          Logger.error('Error selecting chat:', error);
      }
    }, [allMessages, currentChat, showChatBox, user, scrollToBottom]);

    // Message Handling
    const handleWebSocketMessage = useCallback((event) => {
        if (!user?.id) {
            Logger.error('No user ID available in handleWebSocketMessage');
            return;
        }

        try {
            const messageData = JSON.parse(event.data);
            const currentUserId = parseInt(user.id);
            const currentChatId = currentChatIdRef.current;

            if (messageData.type === "online_status") {
                setOnlineUsers(prev => new Set(messageData.online_users.map(id => parseInt(id))));
                return;
            }

            if (messageData.type === 'typing_status') {
                if (messageData.isTyping) {
                    setTypingUsers(prev => new Set(prev).add(messageData.userId));
                } else {
                    setTypingUsers(prev => {
                        const next = new Set(prev);
                        next.delete(messageData.userId);
                        return next;
                    });
                }
                return;
            }
            
            if (messageData.type === "message") {
                const newMessage = {
                  ...messageData,
                  forwarded: messageData.forwarded || false
                };
                const senderId = parseInt(newMessage.sender_id);
                const receiverId = parseInt(newMessage.receiver_id);
                const chatId = senderId === currentUserId ? receiverId : senderId;

                Logger.info('Message details:', {
                    messageId: newMessage.id,
                    senderId,
                    receiverId,
                    currentUserId,
                    currentChatId,
                    computedChatId: chatId,
                    isCurrentChat: currentChatId === chatId,
                });

                // Update allMessages
                setAllMessages(prev => {
                    const existing = prev[chatId] || [];
                    return {
                        ...prev,
                        [chatId]: sortMessages([...existing, newMessage])
                    };
                });

                // Update currentMessages if this is the current chat
                if (chatId === currentChatId) {
                    setCurrentMessages(prev =>
                        sortMessages([...prev, newMessage])
                    );
                
                    // Handle read status
                    if (receiverId === currentUserId && isAtBottom && !newMessage.read &&
                        websocket.current?.readyState === WebSocket.OPEN) {
                        sendWebSocketMessage({
                            type: 'message_status',
                            message_id: newMessage.id,
                            status: 'read',
                            content: newMessage.content
                        });
                    }
                } else if (receiverId === currentUserId) {
                    // Update unread count for other chats
                    setUnreadMessages(prev => ({
                        ...prev,
                        [chatId]: (prev[chatId] || 0) + 1
                    }));
                }
            }

            if (messageData.type === "message_reaction") {
              const { message_id, reaction_counts } = messageData;
              Logger.info('Received reaction update:', {
                  messageId: message_id,
                  reactionCounts: reaction_counts
              });
              setMessageReactions(prev => ({
                  ...prev,
                  [message_id]: reaction_counts
              }));
            }

            if (messageData.type === "message_edit") {
              const { message_id, content, edited_at } = messageData;
              
              // Update message in current messages
              setCurrentMessages(prev =>
                  prev.map(msg =>
                      msg.id === message_id
                          ? { ...msg, content, is_edited: true, edited_at }
                          : msg
                  )
              );
              
              // Update message in all messages
              setAllMessages(prev => {
                  const newMessages = { ...prev };
                  Object.keys(newMessages).forEach(chatId => {
                      newMessages[chatId] = newMessages[chatId].map(msg =>
                          msg.id === message_id
                              ? { ...msg, content, is_edited: true, edited_at }
                              : msg
                      );
                  });
                  return newMessages;
              });
            }

            // Add to WebSocket message handler
            if (messageData.type === "message_delete") {
              const { message_id } = messageData;
              
              // Remove message from current messages
              setCurrentMessages(prev =>
                  prev.filter(msg => msg.id !== message_id)
              );
              
              // Remove message from all messages
              setAllMessages(prev => {
                  const newMessages = { ...prev };
                  Object.keys(newMessages).forEach(chatId => {
                      newMessages[chatId] = newMessages[chatId].filter(msg => 
                          msg.id !== message_id
                      );
                  });
                  return newMessages;
              });
            }

            if (messageData.type === "message_status") {
                updateMessageStatus(messageData);
            }
            
        } catch (error) {
            Logger.error('Error processing WebSocket message:', error, { currentChatId: currentChatIdRef.current });
        }
    }, [user, isAtBottom, updateMessageStatus]);

    // WebSocket Connection
    const connectWebSocket = useCallback(() => {
        const token = localStorage.getItem('token');
        if (!token || !user?.id) {
            Logger.info('Connection requirements not met:', {
                hasToken: !!token,
                userId: user?.id
            });
            return;
        }
    
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            return;
        }
    
        const connect = () => {
            try {
                const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
                const wsUrl = `${wsProtocol}//${window.location.host}/ws/${token}`;
                const ws = new WebSocket(wsUrl);
    
                ws.onopen = () => {
                    websocket.current = ws;
                    Logger.info('WebSocket connected for user:', user.id);
                };
    
                ws.onmessage = (event) => {
                    // Ensure we have the latest user context
                    if (!user?.id) {
                        Logger.error('No user context in message handler');
                        return;
                    }
                    handleWebSocketMessage(event);
                };
    
                ws.onclose = () => {
                    websocket.current = null;
                    Logger.info('WebSocket closed for user:', user.id);
                };
    
            } catch (error) {
                Logger.error('WebSocket connection failed:', error);
                websocket.current = null;
            }
        };
    
        connect();
    }, [user, handleWebSocketMessage]);

    const globalSearch = useCallback(
        async (query) => {
            if (!query.trim()) {
                setGlobalSearchResults({ chats: [], messages: [] });
                setIsSearching(false);
                return;
            }
    
            setIsSearching(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${API_URL}/search?query=${encodeURIComponent(query)}`,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
                setGlobalSearchResults(response.data);
            } catch (error) {
                Logger.error('Failed to search:', error);
                setGlobalSearchResults({ chats: [], messages: [] });
            } finally {
                setIsSearching(false);
            }
        },
        []
    );
    
    const scrollToMessage = useCallback((messageId) => {
      const messageElement = document.querySelector(`[data-message-id="${messageId}"]`);
      if (messageElement) {
          // First remove highlight class from any previously highlighted messages
          document.querySelectorAll('.message.highlight').forEach(el => {
              el.classList.remove('highlight');
          });
  
          // Scroll the message into view
          messageElement.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center'
          });
  
          // Add highlight class
          messageElement.classList.add('highlight');
  
          // Create a temporary overlay for additional visual focus
          const overlay = document.createElement('div');
          overlay.style.position = 'absolute';
          overlay.style.top = '0';
          overlay.style.left = '0';
          overlay.style.right = '0';
          overlay.style.bottom = '0';
          overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
          overlay.style.zIndex = '10';
          overlay.style.pointerEvents = 'none';
          overlay.style.opacity = '0';
          overlay.style.transition = 'opacity 0.5s ease-in-out'; // Increased transition time
  
          const container = messagesContainerRef.current;
          if (container) {
              container.appendChild(overlay);
              void overlay.offsetHeight;
              overlay.style.opacity = '1';
  
              // Fade out and remove overlay after longer duration
              setTimeout(() => {
                  overlay.style.opacity = '0';
                  setTimeout(() => {
                      if (container.contains(overlay)) {
                          container.removeChild(overlay);
                      }
                  }, 500); // Increased fade-out duration
              }, 1500); // Increased visible duration
          }
  
          // Remove highlight class after longer animation
          setTimeout(() => {
              if (messageElement) {
                  messageElement.classList.remove('highlight');
              }
          }, 2500); // Match with CSS animation duration
      }
    }, [messagesContainerRef]);

    const sendMessage = useCallback(() => {
        if (!newMessage.trim() || !currentChat?.id || sendingRef.current) return;
        
        const tempId = `temp-${Date.now()}`;
        sendingRef.current = true;

        const Message = {
            id: tempId,
            content: newMessage.trim(),
            sender_id: user.id,
            receiver_id: currentChat.id,
            timestamp: new Date().toISOString(),
            temp: true
        };  

        setNewMessage('');

        try {
            sendWebSocketMessage({
                type: 'new_send_message',
                content: Message.content,
                receiver_id: currentChat.id,
                temp_id: tempId
            });
        } catch (error) {
            Logger.error('Failed to send message:', error);
        } finally {
            setTimeout(() => {
                sendingRef.current = false;
            }, 500);
        }
    }, [newMessage, currentChat, user]);
    
    // Effects
    useEffect(() => {
        document.body.classList.toggle('dark-theme', isDarkMode);
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        return () => {
            if (userScrollTimeoutRef.current) {
                clearTimeout(userScrollTimeoutRef.current);
            }
            typingTimeouts.current.forEach(timeout => clearTimeout(timeout));
            typingTimeouts.current.clear();
        };
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) return;
    
        const verifyToken = async () => {
            try {
                const response = await axios.get(`${API_URL}/verify-token`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                
                setUser({
                    id: response.data.id,
                    username: response.data.username
                });
                
            } catch (error) {
                localStorage.removeItem('token');
                setUser(null);
                setPage('login');
            }
        };
    
        // Initial verification
        verifyToken();
    
        // Set up interval for subsequent checks
        verifyTimeoutRef.current = setInterval(verifyToken, 300000); // 5 minutes
    
        return () => {
            if (verifyTimeoutRef.current) {
                clearInterval(verifyTimeoutRef.current);
            }
        };
    }, []);

    // Cleanup
    useEffect(() => {
        return () => {
            if (websocket.current) {
                websocket.current.close();
            }
            if (userScrollTimeoutRef.current) {
                clearTimeout(userScrollTimeoutRef.current);
            }
        };
    }, []);

    // Add an effect to monitor currentChat changes
    useEffect(() => {
        currentChatIdRef.current = currentChat?.id || null;
        Logger.info('Updated currentChatIdRef:', { 
            currentChatId: currentChatIdRef.current,
            currentChat 
        });
    }, [currentChat]);

    useEffect(() => {
        if (user?.id) {
            connectWebSocket();
        }
    }, [user, connectWebSocket]);

    useEffect(() => {
        const fetchInitialUnreadMessages = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/unread-messages`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const unreadData = response.data.unread_messages.reduce((acc, { sender_id, unread_count }) => {
                    acc[sender_id] = unread_count;
                    return acc;
                }, {});
                setUnreadMessages(unreadData);
                Logger.info('Fetched initial unread messages:', response.data);
            } catch (error) {
                Logger.error('Failed to fetch initial unread messages:', error);
            }
        };

        if (user?.id) {
            fetchInitialUnreadMessages();
        }
    }, [user]);

    useEffect(() => {
        const savedUnreadMessages = localStorage.getItem('unreadMessages');
        if (savedUnreadMessages) {
            setUnreadMessages(JSON.parse(savedUnreadMessages));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('unreadMessages', JSON.stringify(unreadMessages));
        Logger.info('Saved unread messages:', unreadMessages);
    }, [unreadMessages]);

    useEffect(() => {
        if (currentChat?.id && websocket.current) {
            const handleMessageDelivery = () => {
                const chatMessages = allMessages[currentChat.id] || [];
                const pendingMessages = chatMessages.filter(msg => !msg.delivered && msg.sender_id === user?.id);

                pendingMessages.forEach(msg => {
                    sendWebSocketMessage({
                        type: 'message_status',
                        message_id: msg.id,
                        status: 'delivered',
                        content: msg.content
                    });
                });
            };

            if (onlineUsers.has(currentChat.id)) {
                handleMessageDelivery();
            }
        }
    }, [onlineUsers, currentChat, allMessages, user]);

    // Monitor scroll events
    useEffect(() => {
        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer) {
            const handleScroll = (e) => {
                const { scrollTop, scrollHeight, clientHeight } = e.target;
                const isNearBottom = scrollHeight - scrollTop - clientHeight < 60;
                setIsAtBottom(isNearBottom);
                setShowScrollButton(!isNearBottom);
            };

            messagesContainer.addEventListener('scroll', handleScroll);

            return () => {
                messagesContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [messagesContainerRef]);

    // Scroll to bottom when the last message is from the current user and the user is at the bottom
    useEffect(() => {
        if (currentMessages.length > 0 && isAtBottom) {
            scrollToBottom();
        }
    }, [currentMessages, isAtBottom, scrollToBottom]);

    // Ensure the scroll-to-bottom button is visible when needed
    useEffect(() => {
        if (isAtBottom) {
            setShowScrollButton(false);
        } else {
            setShowScrollButton(true);
        }
    }, [isAtBottom]);

    useEffect(() => {
        return () => {
            // debouncedSearch.cancel();
            // debouncedGlobalSearch.cancel();
            debouncedTypingStart.cancel();
            debouncedTypingStop.cancel();
        };
    }, [debouncedTypingStart, debouncedTypingStop]);

    // Render Methods
    // Enhanced Search Component
    const EnhancedSearch = () => {
        const handleSearchSelect = useCallback((contact) => {
            handleChatSelect(contact);
            setGlobalSearchQuery('');
            setGlobalSearchResults({ chats: [], messages: [] });
            setIsTypingGlobalSearchBar(false);
        }, [handleChatSelect]);

        const handleMessageSelect = useCallback((message) => {
            const contact = contacts.find(c => c.id === message.chat_id);
            if (contact) {
                handleChatSelect(contact).then(() => {
                    // Wait for chat to load before scrolling
                    setTimeout(() => {
                        scrollToMessage(message.id);
                    }, 500);
                });
                setGlobalSearchQuery('');
                setGlobalSearchResults({ chats: [], messages: [] });
                setIsTypingGlobalSearchBar(false);
            }
        }, [contacts, handleChatSelect, scrollToMessage]);

        // Memoize filtered contacts
        const filteredContacts = useMemo(() => {
            if (!globalSearchQuery) return [];
            const query = globalSearchQuery.toLowerCase();
            return contacts.filter(contact => 
                contact.username.toLowerCase().includes(query)
            );
        }, [contacts, globalSearchQuery]);

        return (
            <div className="search-container">
                <div className="search-input-wrapper">
                    <input
                        ref={globalSearchInputRef}
                        className="search-input"
                        type="text"
                        placeholder="Search chats and messages..."
                        value={globalSearchQuery}
                        onClick={() => setIsTypingGlobalSearchBar(true)}
                        onChange={(e) => {
                            e.stopPropagation();
                            const value = e.target.value;
                            setGlobalSearchQuery(value);
                            
                            if (value.trim()) {
                                globalSearch(value);
                            } else {
                                setGlobalSearchResults({ chats: [], messages: [] });
                            }
                        }}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                            if (e.key === 'Escape') {
                                setGlobalSearchQuery('');
                                setGlobalSearchResults({ chats: [], messages: [] });
                                setIsTypingGlobalSearchBar(false);
                            }
                        }}
                        autoComplete="off"
                        spellCheck="false"
                        autoFocus={isTypingGlobalSearchBar && !isTypingMessage}
                    />
                    {globalSearchQuery && (
                        <button 
                            className="search-clear-button"
                            onClick={() => {
                                setGlobalSearchQuery('');
                                setGlobalSearchResults({ chats: [], messages: [] });
                                globalSearchInputRef.current?.focus();
                            }}
                            title="Clear search"
                        >
                            ×
                        </button>
                    )}
                </div>

                {globalSearchQuery && (
                    <div className="enhanced-search-results">
                        {/* Show loading state */}
                        {isSearching && (
                            <div className="search-loading">
                                <div className="loading-spinner"></div>
                                <span>Searching...</span>
                            </div>
                        )}

                        {/* Show no results message */}
                        {!isSearching && 
                        filteredContacts.length === 0 && 
                        globalSearchResults.messages.length === 0 && (
                            <div className="no-results">
                                No results found for "{globalSearchQuery}"
                            </div>
                        )}

                        {/* Show contacts/chats results */}
                        {filteredContacts.length > 0 && (
                            <div className="search-section">
                                <h3 className="search-section-title">Chats</h3>
                                {filteredContacts.map((contact) => (
                                    <div
                                        key={contact.id}
                                        className="search-result-item"
                                        onClick={() => handleSearchSelect(contact)}
                                    >
                                        <div className="search-result-name">
                                            {contact.username}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Show message results */}
                        {globalSearchResults.messages.length > 0 && (
                            <div className="search-section">
                                <h3 className="search-section-title">Messages</h3>
                                {globalSearchResults.messages.map((message) => (
                                    <div
                                        key={message.id}
                                        className="search-result-item"
                                        onClick={() => handleMessageSelect(message)}
                                    >
                                        <div className="search-result-chat">
                                            {message.chat_name}
                                        </div>
                                        <div className="search-result-content">
                                            {message.content}
                                        </div>
                                        <div className="search-result-info">
                                            <span>
                                                {message.sender_id === user?.id ? 'You' : message.sender_name}
                                            </span>
                                            <span>{formatTime(message.timestamp)}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    // Search Messages Component
    const SearchMessages = () => {
        // Memoize filtered messages for local search
        const filteredMessages = useMemo(() => {
            if (!messageSearchQuery || !currentMessages) return [];
            const query = messageSearchQuery.toLowerCase();
            return currentMessages.filter(message =>
                message.content.toLowerCase().includes(query)
            ).slice(0, 20); // Limit local results for performance
        }, [currentMessages, messageSearchQuery]);

        const handleSearchClose = useCallback(() => {
            setShowMessageSearch(false);
            setMessageSearchQuery('');
            setMessageSearchResults([]);
            setIsTypingMessageSearchBar(false);
        }, []);

        const handleMessageSelect = useCallback((messageId) => {
            scrollToMessage(messageId);
            handleSearchClose();
            setIsTypingMessageSearchBar(false);
        }, [handleSearchClose, scrollToMessage]);

        if (!showMessageSearch) return null;

        return (
            <div className={`search-messages-container ${showMessageSearch ? 'active' : ''}`}>
                <div className="search-messages-header">
                    <div className="search-input-wrapper">
                        <input
                            ref={searchInputRef}
                            className="search-messages-input"
                            type="text"
                            placeholder="Search messages..."
                            value={messageSearchQuery}
                            onClick={() => {
                                setIsTypingMessageSearchBar(true);
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                setMessageSearchQuery(value);
                                
                                if (value.trim()) {
                                    searchMessages(value);
                                } else {
                                    setMessageSearchResults([]);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    handleSearchClose();
                                    setIsTypingMessageSearchBar(false);
                                }
                            }}
                            autoComplete="off"
                            spellCheck="false"
                            autoFocus={isTypingMessageSearchBar && !isTypingMessage}
                        />
                        {messageSearchQuery && (
                            <button 
                                className="search-clear-button"
                                onClick={() => {
                                    setMessageSearchQuery('');
                                    setMessageSearchResults([]);
                                    // debouncedSearch.cancel();
                                    searchInputRef.current?.focus();
                                }}
                                title="Clear search"
                            >
                                ×
                            </button>
                        )}
                    </div>
                    <button 
                        className="search-messages-close"
                        onClick={handleSearchClose}
                        title="Close search"
                    >
                        ×
                    </button>
                </div>

                <div className="search-results">
                    {/* Show loading state */}
                    {isSearching && (
                        <div className="search-loading">
                            <div className="loading-spinner"></div>
                            <span>Searching...</span>
                        </div>
                    )}

                    {/* Show no results message */}
                    {!isSearching && 
                    messageSearchQuery && 
                    filteredMessages.length === 0 && 
                    messageSearchResults.length === 0 && (
                        <div className="no-results">
                            No messages found for "{messageSearchQuery}"
                        </div>
                    )}

                    {/* Show local filtered results first for immediate feedback */}
                    {messageSearchQuery && filteredMessages.length > 0 && (
                        <div className="search-section">
                            <h3 className="search-section-title">Quick Results</h3>
                            {filteredMessages.map((message) => (
                                <div
                                    key={message.id}
                                    className="search-result-item"
                                    onClick={() => handleMessageSelect(message.id)}
                                >
                                    <div className="search-result-content">
                                        {message.content}
                                    </div>
                                    <div className="search-result-info">
                                        <span>
                                            {message.sender_id === user?.id ? 'You' : currentChat?.username}
                                        </span>
                                        <span>{formatTime(message.timestamp)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Show server search results */}
                    {messageSearchResults.length > 0 && (
                        <div className="search-section">
                            <h3 className="search-section-title">All Results</h3>
                            {messageSearchResults.map((message) => (
                                <div
                                    key={message.id}
                                    className="search-result-item"
                                    onClick={() => handleMessageSelect(message.id)}
                                >
                                    <div className="search-result-content">
                                        {message.content}
                                    </div>
                                    <div className="search-result-info">
                                        <span>
                                            {message.sender_id === user?.id ? 'You' : currentChat?.username}
                                        </span>
                                        <span>{formatTime(message.timestamp)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const ForwardDialog = () => {
        if (!showForwardDialog) return null;
    
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3>Forward Message</h3>
                        <button 
                            className="modal-close"
                            onClick={() => {
                                setShowForwardDialog(false);
                                setForwardingMessage(null);
                            }}
                        >
                            ×
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="forward-message-preview">
                            {forwardingMessage?.content}
                        </div>
                        <div className="contacts-list forward-contacts">
                            {contacts
                                .filter(contact => contact.id !== currentChat?.id && contact.id !== forwardingMessage?.sender_id)
                                .map(contact => (
                                    <div
                                        key={contact.id}
                                        className="contact forward-contact"
                                        onClick={() => handleForwardMessage(contact.id)}
                                    >
                                        <div className="contact-name">
                                            {contact.username}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Update the message rendering to include reactions
    const renderMessageReactions = (message) => {
      const reactions = messageReactions[message.id] || {};
      
      return Object.entries(reactions).length > 0 ? (
          <div className="message-reactions">
              {Object.entries(reactions).map(([emoji, count]) => (
                  <span key={emoji} className="reaction-badge">
                      {emoji}{count > 1 ? ` ${count}` : ''}
                  </span>
              ))}
          </div>
      ) : null;
    };

    // Update the renderMessages function to include delete option
    const renderMessages = () => {
        const messageGroups = groupMessagesByDate(currentMessages);

        return Object.entries(messageGroups).map(([date, messages], groupIndex) => (
            <div key={date}>
                {groupIndex < Object.entries(messageGroups).length - 1 && (
                    <div className="message-date-header">{date}</div>
                )}
                {messages.map((message) => {
                    const isEditing = editingMessage?.id === message.id;
                    const isOwnMessage = message.sender_id === user?.id;
                    const isDeleting = messageToDelete?.id === message.id;

                    return (
                        <div
                            key={message.id}
                            className={`message ${isOwnMessage ? 'sent' : 'received'}`}
                            data-message-id={message.id}
                        >
                            {/* Edit/Delete/Forward buttons for sent messages */}
                            {message.sender_id === user?.id && !editingMessage?.id === message.id && (
                                <div className="message-actions">
                                    {!message.read && (
                                        <>
                                            <button
                                                className="edit-button"
                                                onClick={() => setEditingMessage(message)}
                                                aria-label="Edit message"
                                            >
                                                ✎
                                            </button>
                                            <button
                                                className="delete-button"
                                                onClick={() => setMessageToDelete(message)}
                                                aria-label="Delete message"
                                            >
                                                🗑️
                                            </button>
                                        </>
                                    )}
                                    <button
                                        className="forward-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setForwardingMessage(message);
                                            setShowForwardDialog(true);
                                        }}
                                        aria-label="Forward message"
                                    >
                                        ↪
                                    </button>
                                </div>
                            )}

                            {/* Edit and delete buttons for read messages (faded out buttons) */}
                            {message.sender_id === user?.id && !editingMessage?.id === message.id && message.read && (
                                <div className="message-actions disabled" title="Cannot edit or delete read messages">
                                    <button
                                        className="edit-button disabled"
                                        disabled
                                        aria-label="Cannot edit read message"
                                    >
                                        ✎
                                    </button>
                                    <button
                                        className="delete-button disabled"
                                        disabled
                                        aria-label="Cannot delete read message"
                                    >
                                        🗑️
                                    </button>
                                </div>
                            )}

                            <div className="message-bubble">
                                <div className="message-content">
                                    {editingMessage?.id === message.id ? (
                                        <div className="edit-message-form">
                                            <textarea
                                                defaultValue={message.content}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                        e.preventDefault();
                                                        handleEditMessage(message.id, e.target.value);
                                                    }
                                                    if (e.key === 'Escape') {
                                                        setEditingMessage(null);
                                                    }
                                                }}
                                                className="edit-textarea"
                                            />
                                        </div>
                                    ) : (
                                        <div className="message-body">
                                            {message.forwarded && (
                                                <div className="forwarded-label">
                                                    <span className="forward-icon">↪</span>
                                                    Forwarded
                                                </div>
                                            )}
                                            <div className="message-text">
                                                {message.content}
                                            </div>
                                        </div>
                                    )}
                                    <div className="message-info">
                                        <div className="timestamp-container">
                                            {message.is_edited && (
                                                <span className="edited-indicator">Edited</span>
                                            )}
                                            <span className="message-timestamp">
                                                {formatTime(message.timestamp)}
                                            </span>
                                        </div>
                                        {message.sender_id === user?.id && <MessageStatus message={message} />}
                                    </div>
                                </div>
                            </div>

                            {/* Forward/Reaction button only for received messages */}
                            {message.sender_id !== user?.id && (
                                <div className="message-actions">
                                    <button
                                        className="forward-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setForwardingMessage(message);
                                            setShowForwardDialog(true);
                                        }}
                                        aria-label="Forward message"
                                    >
                                        ↪
                                    </button>
                                    <button
                                        className="reaction-trigger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowReactionPicker(message.id);
                                        }}
                                        aria-label="Add reaction"
                                    >
                                        😊
                                    </button>
                                </div>
                            )}

                            {/* Reaction picker */}
                            {showReactionPicker === message.id && (
                                <ReactionPicker
                                    onSelect={handleReaction}
                                    onClose={() => setShowReactionPicker(null)}
                                    messageId={message.id}
                                />
                            )}

                            {/* Display reactions */}
                            {renderMessageReactions(message)}

                            {/* Delete confirmation modal */}
                            {messageToDelete?.id === message.id && (
                                <div className="delete-confirmation">
                                    <div className="delete-confirmation-content">
                                        <p>Delete message?</p>
                                        <div className="delete-actions">
                                            <button onClick={() => setMessageToDelete(null)}>
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => handleDeleteMessage(message.id)}
                                                className="delete-confirm"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        ));
    };
      
    const renderMobilePage = () => {
      switch (page) {
          case 'login':
              return (
                  <div className={`auth-page ${isDarkMode ? 'dark-theme' : ''}`}>
                      <div className="auth-container">
                          <div className="auth-header">
                              <h1>Chat App</h1>
                              <button
                                  onClick={() => setIsDarkMode(!isDarkMode)}
                                  className="theme-toggle-btn"
                              >
                                  {isDarkMode ? '☀️' : '🌙'}
                              </button>
                          </div>
                          <LoginForm onLogin={handleLogin} error={error} />
                          <p className="auth-switch">
                              Don't have an account?{' '}
                              <button onClick={() => setPage('register')}>Register</button>
                          </p>
                      </div>
                  </div>
              );
  
          case 'register':
              return (
                  <div className={`auth-page ${isDarkMode ? 'dark-theme' : ''}`}>
                      <div className="auth-container">
                          <div className="auth-header">
                              <h1>Chat App</h1>
                              <button
                                  onClick={() => setIsDarkMode(!isDarkMode)}
                                  className="theme-toggle-btn"
                              >
                                  {isDarkMode ? '☀️' : '🌙'}
                              </button>
                          </div>
                          <RegisterForm onRegister={handleRegister} error={error} />
                          <p className="auth-switch">
                              Already have an account?{' '}
                              <button onClick={() => setPage('login')}>Login</button>
                          </p>
                      </div>
                  </div>
              );
  
          case 'chat':
              return (
                <>
                  <div className={`chat-container ${isDarkMode ? 'dark-theme' : ''}`}>
                      {showChatBox ? (
                          <div className="chat-main">
                              {currentChat ? (
                                  <>
                                      <div className="chat-header-mobile">
                                          <div className="chat-header-info-mobile">
                                              <button onClick={() => setShowChatBox(false)} className="back-button">
                                                  ←
                                              </button>
                                              <h3>{currentChat.username}</h3>
                                              <span className="chat-status">
                                                  {typingUsers.has(currentChat.id) ? (
                                                      <span className="typing-status">typing...</span>
                                                  ) : onlineUsers.has(currentChat.id) ? (
                                                      <span className="online-status">online</span>
                                                  ) : currentChat.last_seen ? (
                                                      <span className="last-seen-status">
                                                          Last seen {formatLastSeen(currentChat.last_seen)}
                                                      </span>
                                                  ) : ('Never seen')}
                                              </span>
                                              <div className="chat-header-actions">
                                                  <button
                                                      className="search-toggle"
                                                      onClick={() => {
                                                        setShowMessageSearch(true);
                                                      }}
                                                  >
                                                      🔍
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                      <SearchMessages />
                                      <div
                                          className="messages-container-mobile"
                                          ref={messagesContainerRef}
                                          onScroll={handleScroll}
                                      >
                                          {renderMessages()}
                                      </div>
                                      <ScrollToBottomButton
                                          onClick={scrollToBottom}
                                          visible={showScrollButton}
                                      />
                                      <div className="message-input-mobile">
                                      <textarea
                                        value={newMessage}
                                        onChange={(e) => {
                                            e.stopPropagation(); // Stop event bubbling
                                            setNewMessage(e.target.value);
                                            setIsTypingMessage(true); // Set typing state
                                            debouncedTypingStart(() => sendTypingStatus(true));
                                            debouncedTypingStop(() => sendTypingStatus(false));
                                        }}
                                        onKeyDown={(e) => {
                                            e.stopPropagation(); // Stop event bubbling
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                debouncedTypingStart.cancel();
                                                debouncedTypingStop.cancel();
                                                sendTypingStatus(false);
                                                sendMessage();
                                            }
                                        }}
                                        onFocus={() => {
                                            setIsTypingMessage(true);
                                        }}
                                        onBlur={() => {
                                            setIsTypingMessage(false);
                                            debouncedTypingStart.cancel();
                                            debouncedTypingStop.cancel();
                                            sendTypingStatus(false);
                                        }}
                                        placeholder="Type a message..."
                                        rows="1"
                                      />
                                          <button
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  debouncedTypingStart.cancel();
                                                  debouncedTypingStop.cancel();
                                                  sendTypingStatus(false);
                                                  sendMessage();
                                              }}
                                          >
                                              Send
                                          </button>
                                      </div>
                                  </>
                              ) : (
                                  <div className="no-chat-selected">
                                      <h3>Welcome to Chat App</h3>
                                      <p>Select a contact to start chatting</p>
                                  </div>
                              )}
                          </div>
                      ) : (
                          <div className="chat-main">
                              <div className="user-header">
                                  <h3>{user?.username}</h3>
                                  <div className="header-controls">
                                      <button
                                          onClick={() => setIsDarkMode(!isDarkMode)}
                                          className="theme-toggle-btn"
                                      >
                                          {isDarkMode ? '☀️' : '🌙'}
                                      </button>
                                      <button 
                                          onClick={handleLogout} 
                                          className="logout-btn" 
                                          title="Logout"
                                      >
                                          ⎋
                                      </button>
                                  </div>
                              </div>
                                <EnhancedSearch />
                              <div className="contacts-list">
                                  {contacts
                                      .filter(contact =>
                                          contact.username.toLowerCase().includes(globalSearchQuery.toLowerCase())
                                      )
                                      .map(contact => {
                                          const contactMessages = allMessages[contact.id] || [];
                                          const lastMessage = contactMessages[contactMessages.length - 1];
                                          const unreadCount = unreadMessages[contact.id] || 0;
  
                                          return (
                                              <div
                                                  key={contact.id}
                                                  className={`contact ${currentChat?.id === contact.id ? 'active' : ''}`}
                                                  onClick={() => handleChatSelect(contact)}
                                              >
                                                  <div className="contact-info">
                                                      <div className="contact-header">
                                                          <span className="contact-name">
                                                              {contact.username}
                                                          </span>
                                                          {unreadCount > 0 && (
                                                              <span className="unread-badge">
                                                                  {unreadCount}
                                                              </span>
                                                          )}
                                                      </div>
                                                      {lastMessage && (
                                                          <div className="contact-last-message">
                                                              <span className="message-preview">
                                                                  {lastMessage.sender_id === user?.id ? 'You: ' : ''}
                                                                  {lastMessage.content}
                                                              </span>
                                                              <span className="message-time">
                                                                  {formatTime(lastMessage.timestamp)}
                                                              </span>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          );
                                      })}
                              </div>
                          </div>
                      )}
                  </div>
                  <ForwardDialog />
                </>
              );
          default:
              return null;
      }
    };
      
    const renderPage = () => {
      if (isMobile) {
          return renderMobilePage();
      }
  
      return (
          <div className={`app ${isDarkMode ? 'dark-theme' : ''}`}>
              {page === 'login' && (
                  <div className={`auth-page ${isDarkMode ? 'dark-theme' : ''}`}>
                      <div className="auth-container">
                          <div className="auth-header">
                              <h1>Chat App</h1>
                              <button
                                  onClick={() => setIsDarkMode(!isDarkMode)}
                                  className="theme-toggle-btn"
                              >
                                  {isDarkMode ? '☀️' : '🌙'}
                              </button>
                          </div>
                          <LoginForm onLogin={handleLogin} error={error} />
                          <p className="auth-switch">
                              Don't have an account?{' '}
                              <button onClick={() => setPage('register')}>Register</button>
                          </p>
                      </div>
                  </div>
              )}
  
              {page === 'register' && (
                  <div className={`auth-page ${isDarkMode ? 'dark-theme' : ''}`}>
                      <div className="auth-container">
                          <div className="auth-header">
                              <h1>Chat App</h1>
                              <button
                                  onClick={() => setIsDarkMode(!isDarkMode)}
                                  className="theme-toggle-btn"
                              >
                                  {isDarkMode ? '☀️' : '🌙'}
                              </button>
                          </div>
                          <RegisterForm onRegister={handleRegister} error={error} />
                          <p className="auth-switch">
                              Already have an account?{' '}
                              <button onClick={() => setPage('login')}>Login</button>
                          </p>
                      </div>
                  </div>
              )}
  
              {page === 'chat' && (
                  <div className={`chat-container ${isDarkMode ? 'dark-theme' : ''}`}>
                      <div className="chat-sidebar">
                          <div className="user-header">
                              <h3>{user?.username}</h3>
                              <div className="header-controls">
                                  <button
                                      onClick={() => setIsDarkMode(!isDarkMode)}
                                      className="theme-toggle-btn"
                                  >
                                      {isDarkMode ? '☀️' : '🌙'}
                                  </button>
                                  <button onClick={handleLogout} className="logout-btn">
                                      Logout
                                  </button>
                              </div>
                          </div>
                            <EnhancedSearch />
                          <div className="contacts-list">
                              {contacts
                                  .filter(contact =>
                                      contact.username.toLowerCase().includes(globalSearchQuery.toLowerCase())
                                  )
                                  .map(contact => {
                                      const contactMessages = allMessages[contact.id] || [];
                                      const lastMessage = contactMessages[contactMessages.length - 1];
                                      const unreadCount = unreadMessages[contact.id] || 0;
  
                                      return (
                                          <div
                                              key={contact.id}
                                              className={`contact ${currentChat?.id === contact.id ? 'active' : ''}`}
                                              onClick={() => handleChatSelect(contact)}
                                          >
                                              <div className="contact-info">
                                                  <div className="contact-header">
                                                      <span className="contact-name">
                                                          {contact.username}
                                                      </span>
                                                      {unreadCount > 0 && (
                                                          <span className="unread-badge">
                                                              {unreadCount}
                                                          </span>
                                                      )}
                                                  </div>
                                                  {lastMessage && (
                                                      <div className="contact-last-message">
                                                          <span className="message-preview">
                                                              {lastMessage.sender_id === user?.id ? 'You: ' : ''}
                                                              {lastMessage.content}
                                                          </span>
                                                          <span className="message-time">
                                                              {formatTime(lastMessage.timestamp)}
                                                          </span>
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                      );
                                  })}
                          </div>
                      </div>
                      <div className="chat-main">
                          {currentChat ? (
                              <>
                                  <div className="chat-header">
                                      <div className="chat-header-info">
                                          <h3>{currentChat.username}</h3>
                                          <span className="chat-status">
                                              {typingUsers.has(currentChat.id) ? (
                                                  <span className="typing-status">typing...</span>
                                              ) : onlineUsers.has(currentChat.id) ? (
                                                  <span className="online-status">online</span>
                                              ) : currentChat.last_seen ? (
                                                  <span className="last-seen-status">
                                                      Last seen {formatLastSeen(currentChat.last_seen)}
                                                  </span>
                                              ) : ('Never seen')}
                                          </span>
                                      </div>
                                      <div className="chat-header-actions">
                                          <button
                                              className="search-toggle"
                                              onClick={() => {
                                                Logger.info('Clicked on search button');
                                                setShowMessageSearch(true);
                                              }}
                                          >
                                              🔍
                                          </button>
                                      </div>
                                  </div>
                                  <SearchMessages />
                                  <div
                                      className="messages-container"
                                      ref={messagesContainerRef}
                                      onScroll={handleScroll}
                                  >
                                      {renderMessages()}
                                  </div>
                                  <ScrollToBottomButton
                                      onClick={scrollToBottom}
                                      visible={showScrollButton}
                                  />
                                  <div className="message-input">
                                    <textarea
                                      value={newMessage}
                                      onClick={() => setIsTypingMessage(true)}
                                      onChange={(e) => {
                                          e.stopPropagation(); // Stop event bubbling
                                          setNewMessage(e.target.value);
                                          setIsTypingMessage(true); // Set typing state
                                          debouncedTypingStart(() => sendTypingStatus(true));
                                          debouncedTypingStop(() => sendTypingStatus(false));
                                      }}
                                      onKeyDown={(e) => {
                                          e.stopPropagation(); // Stop event bubbling
                                          if (e.key === 'Enter' && !e.shiftKey) {
                                              e.preventDefault();
                                              debouncedTypingStart.cancel();
                                              debouncedTypingStop.cancel();
                                              sendTypingStatus(false);
                                              sendMessage();
                                          }
                                      }}
                                      onFocus={() => {
                                          setIsTypingMessage(true);
                                      }}
                                      onBlur={() => {
                                          setIsTypingMessage(false);
                                          debouncedTypingStart.cancel();
                                          debouncedTypingStop.cancel();
                                          sendTypingStatus(false);
                                      }}
                                      placeholder="Type a message..."
                                      rows="1"
                                    />
                                      <button
                                          onClick={(e) => {
                                              e.preventDefault();
                                              debouncedTypingStart.cancel();
                                              debouncedTypingStop.cancel();
                                              sendTypingStatus(false);
                                              sendMessage();
                                          }}
                                      >
                                          Send
                                      </button>
                                  </div>
                              </>
                          ) : (
                              <div className="no-chat-selected">
                                  <h3>Welcome to Chat App</h3>
                                  <p>Select a contact to start chatting</p>
                              </div>
                          )}
                      </div>
                  </div>
              )}
              <ForwardDialog />
          </div>
      );
    };
    
    return <div className={`app ${isDarkMode ? 'dark-theme' : ''}`}>{renderPage()}</div>;
}

export default App;